import React, { useEffect, useState } from "react";
import { FaCheck, FaEnvelope, FaExclamation } from "react-icons/fa";
import { Button } from "@chakra-ui/react";
import { sendEmailVerification } from "firebase/auth";
import mixpanel from "mixpanel-browser";

import { Link } from "components/Link";
import { LogoIcon } from "components/Logo/LogoIcon";
import { H4, P } from "components/Typography";
import { routes } from "constants/routes";
import { useAuth } from "hooks/useFirebaseAuth";
import { firebaseAuth } from "providers/firebase";
import { MixPanelEvent } from "types/MixPanel";

enum ResendState {
  LOADING = "loading",
  SENT = "sent",
  FAILED = "failed",
  IDLE = "idle",
}

const RESEND_DELAY = 20;

export const VerifyEmailPage = () => {
  const [resendState, setResentState] = useState<ResendState>(ResendState.IDLE);
  const { authUser, signOut } = useAuth();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCounter(counter + 1);
    }, 1000);
  }, [counter]);

  // hack to get emailVerified to update
  useEffect(() => {
    if (!authUser?.emailVerified) {
      const emailVerificationCheckInterval = setInterval(async () => {
        if (!firebaseAuth.currentUser) {
          return;
        }

        try {
          await firebaseAuth.currentUser.reload();
        } catch (err) {}

        if (firebaseAuth.currentUser?.emailVerified) {
          clearInterval(emailVerificationCheckInterval);
        }
      }, 5000);
    }
  }, []);

  const handleResendVerification = async () => {
    setResentState(ResendState.LOADING);
    if (authUser) {
      try {
        await sendEmailVerification(authUser);
        mixpanel.track(MixPanelEvent.EMAIL_VERIFICATION_RESENT, {
          email: authUser.email,
        });
        setResentState(ResendState.SENT);
      } catch (err) {
        setResentState(ResendState.FAILED);
      }
    }
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="space-y-4 text-center">
        <div className="flex justify-center">
          <Link isPlain href={routes.home.href}>
            <LogoIcon />
          </Link>
        </div>
        <H4>Email Verification Needed</H4>
        <P>
          Please verify your email address to unlock our full suite of seller
          tools.
        </P>
        <div>
          <Button
            colorScheme="blue"
            onClick={handleResendVerification}
            className="flex justify-center"
            disabled={
              counter < RESEND_DELAY ||
              resendState === ResendState.SENT ||
              resendState === ResendState.FAILED
            }
            isLoading={resendState === ResendState.LOADING}
          >
            {resendState === ResendState.SENT && (
              <div className="flex items-center">
                <span className="mr-2">
                  <FaCheck />
                </span>
                Verification Email Sent
              </div>
            )}
            {resendState === ResendState.IDLE && (
              <div className="flex items-center">
                <span className="mr-2">
                  <FaEnvelope />
                </span>
                Resend verification email{" "}
                {counter < RESEND_DELAY && `(in ${RESEND_DELAY - counter}s)`}
              </div>
            )}
            {resendState === ResendState.FAILED && (
              <div className="flex items-center">
                <span className="mr-2">
                  <FaExclamation />
                </span>
                Failed to send verification email
              </div>
            )}
          </Button>
        </div>
        <Button onClick={signOut}>Sign out</Button>
        <P fontColor="secondary" fontSize="sm">
          Having issues verifying your email?{" "}
          <Link href={routes.contact.href}>Contact Us</Link>
        </P>
      </div>
    </div>
  );
};
