import React, { createContext, useContext, useEffect, useState } from "react";
import { signInWithEmailAndPassword, User } from "firebase/auth";
import mixpanel from "mixpanel-browser";

import { firebaseAuth } from "providers/firebase";
import { getBrowserFingerprint } from "utils/finger";

export interface Auth {
  authUser: User | null;
  isLoading: boolean;
  signOut: () => void;
  signInWithEmailAndPassword: typeof signInWithEmailAndPassword;
}

const AuthStateContext = createContext<Auth>({
  authUser: null,
  isLoading: true,
  signInWithEmailAndPassword,
  signOut: () => undefined,
});

export const AuthStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [authUser, setAuthUser] = useState<User | null>(null);
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const authStateChanged = async (authState: User | null) => {
    if (!authState) {
      setAuthUser(null);
      setIsLoading(false);
      return;
    }

    // User has logged in.
    setAuthUser(authState);
    // hack to reload emailVerified
    setEmailVerified(authState.emailVerified);
    mixpanel.identify(authState.uid);
    mixpanel.people.set({
      $email: authState.email,
      emailVerified: authState.emailVerified,
    });
    mixpanel.people.union({
      browserFingerprints: [getBrowserFingerprint()],
    });
    setIsLoading(false);
  };

  useEffect(() => {
    const unsubscribe = firebaseAuth.onIdTokenChanged(authStateChanged);
    return () => unsubscribe();
  }, []);

  const clear = () => {
    setAuthUser(null);
  };

  const signOut = () => firebaseAuth.signOut().then(clear);

  return (
    <AuthStateContext.Provider
      value={{
        authUser,
        isLoading,
        signOut,
        signInWithEmailAndPassword,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuth = () => useContext(AuthStateContext);
