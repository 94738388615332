import { NavItem } from "types/Navigation";
import { IS_DEV } from "utils/environment";

interface Config {
  appName: string;
  publicUrl: string;
  publicUrlDisplay: string;
  featureFlags: { [key: string]: boolean };
  googleAnalyticsId: string;
}

const featureFlags = {
  enableLogin: true,
  enableListingOptimiser: false,
  enableEtsyConnect: false,
  enablePricing: true,
  enableAdsense: false,
  enableBanner: false,
  enableAffiliates: true,
  enableStripe: true,
};

export const isFeatureEnabled = (key: keyof typeof featureFlags) =>
  featureFlags[key];

export const config: Config = {
  appName: "ProductFlint",
  publicUrl: IS_DEV ? "http://localhost:3000" : "https://productflint.com",
  publicUrlDisplay: "productflint.com",
  featureFlags,
  googleAnalyticsId: "G-1HDGKLE8ZM",
};

export const buildProdLink = (route: NavItem) =>
  `${config.publicUrl}${route.href}`;
