import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { ListingsSEOSummary } from "@productflint/api/src/types/app/ListingSEO";
import { User } from "@productflint/api/src/types/app/User";
import { getAuth } from "firebase/auth";
import { doc, getFirestore } from "firebase/firestore";
import { useRouter } from "next/router";

import { ListingsContext } from "components/App/ListingsContext";
import { UserContext } from "components/App/UserContext";
import { LoaderFullScreen } from "components/Loaders/LoaderFullScreen";
import { routes } from "constants/routes";
import { firebaseApp } from "providers/firebase";

export const AppDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const [userRaw, loading, err] = useDocumentData(
    doc(getFirestore(firebaseApp), "users", getAuth().currentUser?.uid!)
  );
  const [listingsRaw, isLoadingListings] = useDocumentData(
    doc(
      getFirestore(firebaseApp),
      "listing-optimizer",
      getAuth().currentUser?.uid!
    )
  );

  const user = userRaw as User | undefined;
  const listingSEOSummary = listingsRaw as ListingsSEOSummary | undefined;

  // Return null if user account hasn't loaded.
  if (loading) {
    return <LoaderFullScreen />;
  }

  if (!loading && !user) {
    router.replace(routes.home.href);
  }

  if (!user) {
    return null;
  }

  // add fn here to handle permissions
  return (
    <UserContext.Provider value={user}>
      <ListingsContext.Provider
        value={{
          isLoading: isLoadingListings,
          seoSummary: listingSEOSummary
            ? {
                ...listingSEOSummary,
                listings: listingSEOSummary?.listings.sort((a, b) =>
                  a.title.localeCompare(b.title)
                ),
              }
            : undefined,
        }}
      >
        {children}
      </ListingsContext.Provider>
    </UserContext.Provider>
  );
};
