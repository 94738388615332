import React, { useEffect } from "react";
import { useRouter } from "next/router";

import { VerifyEmailPage } from "components/App/VerifyEmailPage";
import { LoaderFullScreen } from "components/Loaders/LoaderFullScreen";
import { routes } from "constants/routes";
import { useAuth } from "hooks/useFirebaseAuth";

export const AuthenticatedPage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { authUser, isLoading } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!isLoading && !authUser) {
      router.replace(routes.signIn.href);
    }
  }, [authUser, isLoading, router]);

  if (!authUser || !authUser.uid) {
    return <LoaderFullScreen />;
  }

  if (!authUser.emailVerified) {
    return <VerifyEmailPage />;
  }

  return <>{children}</>;
};
