import React from "react";

import {
  Typography,
  TypographyBaseProps,
} from "components/Typography/TypographyElements/Typography";

interface H4Props extends TypographyBaseProps {}

export const H4: React.FC<H4Props> = ({
  children,
  fontColor = "primary",
  fontSize = "2xl",
  fontWeight = "semiBold",
  tracking = "tight",
  ...rest
}) => (
  <Typography
    element="h4"
    fontColor={fontColor}
    fontSize={fontSize}
    fontWeight={fontWeight}
    tracking={tracking}
    {...rest}
  >
    {children}
  </Typography>
);
