import React, { MouseEventHandler, useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Badge, Button } from "@chakra-ui/react";
import clsx from "clsx";
import { useRouter } from "next/router";

import { Link } from "components/Link";
import { P, Span } from "components/Typography";

export interface LinkConfig {
  name: string;
  href?: string;
  icon?: React.ReactNode;
  children?: LinkConfig[];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: string;
  hidden?: boolean;
  isNew?: boolean;
  defaultCollapsed?: boolean;
}

interface SidebarLinkProps {
  link: LinkConfig;
  active?: boolean;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({
  link: { icon, name, href, disabled, onClick, isNew },
}) => {
  const router = useRouter();
  const activeHref = router.asPath.split("?")[0];
  const active =
    href && href.split("/").length > 2
      ? activeHref.includes(href || "")
      : activeHref === href;

  const innerButton = (
    <Button
      disabled={disabled}
      variant="ghost"
      className={clsx("flex gap-2 items-center w-full !justify-start", {
        "!bg-blue-50 !text-blue-600": active,
      })}
      onClick={onClick}
    >
      <div className="flex gap-2 items-center">
        <Span fontSize="sm">{icon}</Span>
        <P
          className={clsx({ "text-blue-600": active })}
          fontSize="sm"
          fontWeight="medium"
        >
          {name}
        </P>
      </div>
      <div>
        {isNew && (
          <div className="text-xs">
            <Badge colorScheme="green">New</Badge>
          </div>
        )}
      </div>
    </Button>
  );

  if (href) {
    return (
      <Link tabIndex={-1} href={href} isPlain>
        <div className="px-2 my-0.5">{innerButton}</div>
      </Link>
    );
  }

  return <div className="px-2 my-1">{innerButton}</div>;
};

interface SidebarItemsProps {
  links: LinkConfig[];
  onClick: () => void;
}

export const SidebarGroup: React.FC<{ link: LinkConfig }> = ({ link }) => {
  const [show, setShow] = useState(!link.defaultCollapsed);

  return (
    <>
      <Button
        variant="ghost"
        size="sm"
        className="p-2 my-0.5 flex items-center !justify-between w-full !hover:bg-none"
        onClick={(e) => {
          e.stopPropagation();
          setShow(!show);
        }}
      >
        <P fontColor="secondary" fontSize="sm" fontWeight="medium">
          {link.name}
        </P>

        <Span fontColor="secondary">
          {show ? <FaCaretUp /> : <FaCaretDown />}
        </Span>
      </Button>
      {show && (
        <div className="">
          {link.children?.map((childLink) => (
            <SidebarLink key={childLink.name} link={childLink} />
          ))}
        </div>
      )}
    </>
  );
};

export const SidebarItems: React.FC<SidebarItemsProps> = ({
  links,
  onClick,
}) => (
  <div onClick={onClick}>
    {links.map((parentLink) => {
      if (parentLink.children) {
        return <SidebarGroup key={parentLink.name} link={parentLink} />;
      }

      return (
        <SidebarLink key={parentLink.name} link={parentLink} active={true} />
      );
    })}
  </div>
);
