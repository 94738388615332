import React from "react";

import { Span } from "components/Typography";
import { config } from "constants/app";

export const Logo: React.FC = () => (
  <div className="flex items-center">
    <img className="h-8 mr-2" src="/logo.svg" alt="ProductFlint Logo" />
    <Span
      className="whitespace-nowrap"
      fontSize="2xl"
      fontWeight="semiBold"
      tracking="tight"
    >
      {config.appName}
    </Span>
  </div>
);
