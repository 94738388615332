import React from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import Script from "next/script";

import { config } from "constants/app";
import { getBrowserFingerprint } from "utils/finger";

const isProduction = process.env.NODE_ENV === "production";

export const GoogleAnalytics: React.FC = () => {
  const [cookies] = useCookies([]);

  // @ts-ignore
  const hasDisabledGA = cookies["env-disable-ga"];

  useEffect(() => {
    const deviceModel = getBrowserFingerprint();

    // @ts-ignore
    if (cookies["env-disable-ga"] || deviceModel === 1594309311) {
      console.log("Tracking disabled.");
    } else {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line no-inner-declarations
      function gtag() {
        // eslint-disable-next-line no-undef
        // @ts-ignore
        dataLayer.push(arguments);
      }
      // @ts-ignore
      gtag("js", new Date());
      // @ts-ignore
      gtag("config", config.googleAnalyticsId);

      // @ts-ignore
      window.gtag = gtag;
    }
  }, []);

  if (hasDisabledGA || !isProduction) {
    return null;
  }

  return (
    <Script
      strategy="lazyOnload"
      src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsId}`}
    />
  );
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const logEvent = (name: string) => {
  // @ts-ignore
  window.gtag?.("event", name);
};
