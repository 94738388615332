import { KeywordSummary } from "@productflint/api/src/types/Keyword";

import { productFlintAPI } from "api/index";
import { replaceSpacesWithPlus } from "components/KeywordFinder/utils/KeywordUtils";

export const getKeywordSummary = async (query: string) => {
  const { data } = await productFlintAPI.get<KeywordSummary>(
    `/listing/keywords/${replaceSpacesWithPlus(query)}`
  );

  return data;
};

export const getTrendingKeywords = async () => {
  const { data } = await productFlintAPI.get<KeywordSummary[]>(
    `/listing/trending/keywords`
  );

  return data;
};

export const getTopShops = async () => {
  const { data } = await productFlintAPI.get<KeywordSummary[]>(`/shop/top`);

  return data;
};

export const storeUserCode = async (
  userId: string,
  code: string,
  codeVerifier: string
) => {
  const { data } = await productFlintAPI.post<KeywordSummary[]>(
    `/etsy-oauth/token-store`,
    { userId, code, codeVerifier }
  );

  return data;
};

export const registerUser = async (uid: string, email: string) => {
  const { data } = await productFlintAPI.post<
    { uid: string; email: string } | undefined
  >(`/user/sign-up`, {
    uid,
    email,
  });

  return data;
};

export const getGeneratedTitle = async (payload: {
  listingDescription: string;
  keywords: string;
}) => {
  const { data } = await productFlintAPI.post(
    `public-ai/listing-title`,
    payload
  );

  return data;
};
