import { PLAN_CONFIGS } from "@productflint/api/src/constants";
import {
  MonthlyToolUseCounts,
  ToolUseCounts,
  User,
} from "@productflint/api/src/types/app/User";
import { PlanTier } from "@productflint/api/src/types/Plan";
import { doc, increment, updateDoc } from "firebase/firestore";
import mixpanel from "mixpanel-browser";

import { firebaseDB } from "providers/firebase";

export const safeIncrementCount = (
  uid: string,
  totalKey: keyof ToolUseCounts,
  monthlyKey?: keyof MonthlyToolUseCounts
) => {
  try {
    updateDoc(doc(firebaseDB, "users", uid), {
      [totalKey]: increment(1),
      ...(monthlyKey && { [monthlyKey]: increment(1) }),
    });

    mixpanel.people.increment(totalKey);
  } catch (e) {}
};

export const getUserPlan = (user?: User) =>
  PLAN_CONFIGS[user?.plan?.planTier || PlanTier.FREE];

export const getUserPaymentProvider = (user?: User) => {
  if (user?.plan && "subscriptionId" in user.plan) {
    return "LS";
  }

  return "STRIPE";
};

export const isToolDisabled = (
  user: User,
  toolKey: keyof MonthlyToolUseCounts
) => {
  const currentUseForMonth = user[toolKey];
  const limit = getUserPlan(user).monthlyLimits[toolKey];

  return currentUseForMonth >= limit;
};
