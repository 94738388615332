import React from "react";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";

import { MixPanelEvent } from "types/MixPanel";

const pageViewHandler = (_: any, { shallow }: any) => {
  if (!shallow) {
    mixpanel.track(MixPanelEvent.PAGE_VIEW);
  }
};

export const MixPanel = () => {
  const router = useRouter();

  React.useEffect(() => {
    mixpanel.track(MixPanelEvent.PAGE_VIEW);

    router.events.on("routeChangeComplete", pageViewHandler);

    return () => {
      router.events.off("routeChangeComplete", pageViewHandler);
    };
  }, []);

  return null;
};
