import React from "react";
import {
  FaChartLine,
  FaCog,
  FaFileExport,
  FaHome,
  FaPenFancy,
  FaQuestionCircle,
  FaSearch,
  FaSearchDollar,
  FaSignal,
  FaSortAmountUp,
  FaTag,
  FaTools,
} from "react-icons/fa";
import { MdGeneratingTokens } from "react-icons/md";

export interface Route {
  name: string;
  href: string;
  icon?: React.ReactNode;
  description?: string;
  makePath?: any;
}

export const routes = {
  home: {
    name: "Home",
    href: "/",
  },
  upgrade: {
    name: "Upgrade",
    href: "/app/upgrade",
  },
  keywordFinder: {
    name: "Etsy Keyword Finder",
    icon: <FaSearch />,
    href: "/keyword-finder",
    description:
      "Perfect for keyword research, crafting your listing title, tags & description.",
  },
  keywordFinderApp: {
    name: "Keyword Finder",
    icon: <FaSearch />,
    href: "/app/keyword-finder",
    description:
      "Perfect for keyword research, crafting your listing title, tags & description.",
  },
  shopAnalyzer: {
    name: "Shop Analyzer",
    icon: <FaChartLine />,
    href: "/app/shop-analyzer",
    description: "Unlock the secrets of your competitors' Etsy success.",
  },
  featuresShopAnalyzer: {
    name: "Etsy Shop Analyzer",
    icon: <FaChartLine />,
    href: "/features/shop-analyzer",
    description: "Unlock the secrets of your competitors' Etsy success.",
  },
  listingExplorer: {
    name: "Listing Explorer",
    icon: <FaSearchDollar />,
    href: "/app/listing-explorer",
    description:
      "View hidden statistics for Etsy listings such as total sales, favorites and revenue.",
  },
  featuresListingExplorer: {
    name: "Etsy Listing Explorer",
    icon: <FaSearchDollar />,
    href: "/features/listing-explorer",
    description:
      "View hidden statistics for Etsy listings such as total sales, favorites and revenue.",
  },
  trendingKeywords: {
    name: "Etsy Trending Keywords",
    icon: <FaSignal />,
    href: "/trending-keywords",
    description: "The best way to find the newest, most popular items on Etsy.",
  },
  tagExtractor: {
    name: "Etsy Tag Extractor",
    icon: <FaFileExport />,
    href: "/tag-extractor",
    description:
      "Extract and copy keywords from competitors listings with just one click.",
  },
  tagGenerator: {
    name: "Etsy Tag Generator",
    icon: <FaTag />,
    href: "/tag-generator",
    description:
      "Find similar Etsy Tags that suit your listings in just a few seconds.",
  },
  tagGeneratorApp: {
    name: "Etsy Tag Generator",
    icon: <FaTag />,
    href: "/app/tag-generator",
    description:
      "Find similar Etsy Tags that suit your listings in just a few seconds.",
  },
  topSellers: {
    name: "Top Etsy Sellers",
    icon: <FaSortAmountUp />,
    href: "/top-etsy-sellers",
    description:
      "Gain insights and view statistics on the worlds top Etsy sellers.",
  },
  blog: {
    name: "Blog",
    href: "/blog",
  },
  pricing: {
    name: "Plans & Pricing",
    href: "/pricing",
  },
  affiliates: {
    name: "Affiliates",
    href: "/affiliates",
  },
  tags: {
    name: "Tags",
    href: "/tags",
  },
  tag: {
    name: "Tag",
    href: "/tag",
  },
  contact: {
    name: "Contact",
    href: "/contact",
    icon: <FaQuestionCircle />,
  },
  forgotPassword: {
    name: "Forgot Password",
    href: "/forgot-password",
  },
  privacyPolicy: {
    name: "Privacy Policy",
    href: "/privacy-policy",
  },
  termsOfService: {
    name: "Terms of Service",
    href: "/terms-of-service",
  },
  cookiePolicy: {
    name: "Cookie Policy",
    href: "/cookie-policy",
  },
  writeForUs: {
    name: "Write For Us",
    href: "/blog/write-for-us",
  },
  signIn: {
    name: "Sign In",
    href: "/sign-in",
  },
  signUp: {
    name: "Sign Up",
    href: "/sign-up",
  },
  dashboard: {
    name: "Dashboard",
    href: "/app",
    icon: <FaHome />,
  },
  settings: {
    name: "Settings",
    href: "/app/settings",
    icon: <FaCog />,
  },
  settingsUsage: {
    name: "Settings Usage",
    href: "/app/settings?tab=2",
    icon: <FaCog />,
  },
  listingOptimizer: {
    name: "Listing Optimizer",
    icon: <FaTools />,
    href: "/app/listing-optimizer",
    makePath: (listingId: string | number) =>
      `/app/listing-optimizer/${listingId}`,
  }
};

export const affiliateJoinLink = "https://productflint.trackdesk.com/sign-up";
export const feedbackFormLink = "https://forms.gle/ntZ5T1s4XyaxVLxz5";
