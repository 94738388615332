import { PaymentFrequency, PlanTier } from "@productflint/api/src/types/Plan";
import { getAuth } from "firebase/auth";

import { productFlintAPI } from "api/index";

export const linkShop = async (shopName: string) => {
  const { data } = await productFlintAPI.post<{ id: number }>(
    `app/shop/link/${shopName}`,
    {},
    {
      headers: {
        authtoken: await getAuth().currentUser?.getIdToken(),
      },
    }
  );

  return data;
};

export const syncShop = async (shopId: number) => {
  const { data } = await productFlintAPI.post(
    `app/shop/sync/${shopId}`,
    {},
    {
      headers: {
        authtoken: await getAuth().currentUser?.getIdToken(),
      },
    }
  );

  return data;
};

export const syncShopListing = async (listingId: number) => {
  const { data } = await productFlintAPI.post(
    `app/shop/sync/listing/${listingId}`,
    {},
    {
      headers: {
        authtoken: await getAuth().currentUser?.getIdToken(),
      },
    }
  );

  return data;
};

export const deleteLinkedShop = async (shopName: string) => {
  const { data } = await productFlintAPI.delete(`app/shop/link/${shopName}`, {
    headers: {
      authtoken: await getAuth().currentUser?.getIdToken(),
    },
  });

  return data;
};

export const updateUserSubscription = async (
  planTier: PlanTier,
  paymentFrequency: PaymentFrequency
) => {
  const { data } = await productFlintAPI.patch<string>(
    `app/subscription`,
    {
      planTier,
      paymentFrequency,
    },
    {
      headers: {
        authtoken: await getAuth().currentUser?.getIdToken(),
      },
    }
  );

  return data;
};

export const cancelUserSubscription = async () => {
  const { data } = await productFlintAPI.delete<string>(`app/subscription`, {
    headers: {
      authtoken: await getAuth().currentUser?.getIdToken(),
    },
  });

  return data;
};

export const reactivateUserSubscription = async () => {
  const { data } = await productFlintAPI.post<string>(
    `app/subscription/reactivate`,
    {},
    {
      headers: {
        authtoken: await getAuth().currentUser?.getIdToken(),
      },
    }
  );

  return data;
};

export const createCheckout = async (tier: PlanTier, monthly: boolean) => {
  const {
    data: { redirectUrl },
  } = await productFlintAPI.post<{ redirectUrl: string }>(
    `app/subscription-v2/create-checkout-session`,
    {
      tier,
      monthly,
    },
    {
      headers: {
        authtoken: await getAuth().currentUser?.getIdToken(),
      },
    }
  );

  return redirectUrl;
};

export const createBillingPortal = async () => {
  const {
    data: { redirectUrl },
  } = await productFlintAPI.post<{ redirectUrl: string }>(
    `app/subscription-v2/create-billing-portal`,
    {},
    {
      headers: {
        authtoken: await getAuth().currentUser?.getIdToken(),
      },
    }
  );

  return redirectUrl;
};
