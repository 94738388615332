import React from "react";
import classnames from "clsx";

export const Container: React.FC<{
  children?: React.ReactNode;
  size?: "medium" | "large";
  id?: string;
  className?: string;
  wrapperClassName?: string;
}> = ({ id, size = "medium", className, children, wrapperClassName }) => (
  <div className={wrapperClassName}>
    <div
      id={id}
      className={classnames(
        "mx-auto p-4 sm:px-6 lg:px-8",
        { "max-w-6xl": size === "medium" },
        { "max-w-7xl": size === "large" },
        className
      )}
    >
      {children}
    </div>
  </div>
);
