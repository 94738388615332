import { createStandaloneToast, UseToastOptions } from "@chakra-ui/react";

export const defaultToastOptions: UseToastOptions = {
  position: "bottom",
  duration: 4000,
};

const { ToastContainer, toast } = createStandaloneToast({
  defaultOptions: defaultToastOptions,
});

export { ToastContainer, toast };
