import React from "react";
import clsx from "clsx";
import mixpanel from "mixpanel-browser";
import NextLink from "next/link";

import { Span, SpanProps } from "components/Typography";
import { MixPanelEvent } from "types/MixPanel";

interface LinkProps extends SpanProps {
  href: string;
  children: React.ReactNode;
  className?: string;
  rel?: string;
  isPlain?: boolean;
  openInNewTab?: boolean;
  tabIndex?: number;
  onClick?: (href: string) => void;
  underline?: boolean;
}

export const Link: React.FC<LinkProps> = ({
  children,
  href,
  className,
  rel,
  openInNewTab,
  isPlain,
  fontColor = "accent",
  tabIndex,
  onClick,
  underline,
  ...rest
}) => {
  const isInternal = !href.includes("http");

  const styledLink = (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      tabIndex={tabIndex}
      href={href}
      rel={rel || (openInNewTab ? "noreferrer" : "")}
      target={openInNewTab ? "_blank" : ""}
      className={clsx(className, {
        "no-underline": !underline,
        underline: underline,
      })}
      onClick={() => {
        mixpanel.track(MixPanelEvent.LINK_CLICKED, {
          href,
        });
        onClick?.(href);
      }}
    >
      {isPlain ? (
        children
      ) : (
        <Span
          className={clsx(className, "hover:underline")}
          fontColor={fontColor}
          {...rest}
        >
          {children}
        </Span>
      )}
    </a>
  );

  if (!isInternal) {
    return styledLink;
  }

  return (
    <NextLink rel={rel} href={href} passHref>
      {styledLink}
    </NextLink>
  );
};
