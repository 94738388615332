import React from "react";
import clsx from "clsx";

import {
  TypographyCasing,
  typographyCasingClassNames,
  TypographyColor,
  typographyColorClassNames,
  typographyFontSizeClassNames,
  TypographyFontWeight,
  typographyFontWeightClassNames,
  TypographySize,
  TypographyTracking,
  typographyTrackingClassNames,
} from "components/Typography/utils/typographyClassNames";

export interface TypographyBaseProps {
  children: React.ReactNode;
  fontColor?: TypographyColor;
  fontSize?: TypographySize;
  fontWeight?: TypographyFontWeight;
  tracking?: TypographyTracking;
  casing?: TypographyCasing;
  className?: string;
}

export interface TypographyProps extends TypographyBaseProps {
  element: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
}

export const Typography: React.FC<TypographyProps> = ({
  children,
  element,
  fontColor,
  fontSize,
  fontWeight,
  tracking,
  casing,
  className,
}) =>
  React.createElement(
    element,
    {
      className: clsx(
        className,
        fontColor && typographyColorClassNames[fontColor],
        fontSize && typographyFontSizeClassNames[fontSize],
        fontWeight && typographyFontWeightClassNames[fontWeight],
        tracking && typographyTrackingClassNames[tracking],
        casing && typographyCasingClassNames[casing]
      ),
    },
    children
  );
