import React from "react";

import {
  Typography,
  TypographyBaseProps,
} from "components/Typography/TypographyElements/Typography";

export interface SpanProps extends TypographyBaseProps {}

export const Span: React.FC<SpanProps> = ({
  children,
  fontColor,
  fontSize,
  fontWeight,
  ...rest
}) => (
  <Typography
    element="span"
    fontColor={fontColor}
    fontSize={fontSize}
    fontWeight={fontWeight}
    {...rest}
  >
    {children}
  </Typography>
);
