import React from "react";
import clsx from "clsx";

import { Link } from "components/Link";
import { P } from "components/Typography";
import { isFeatureEnabled } from "constants/app";
import { routes } from "constants/routes";

interface BannerProps {
  className?: string;
}

export const Banner: React.FC<BannerProps> = ({ className }) => {
  if (!isFeatureEnabled("enableBanner")) {
    return null;
  }

  return (
    <div
      className={clsx("w-full text-center p-2", className)}
      style={{ backgroundColor: "#38a169" }}
    >
      <P fontSize="sm" fontColor="inversePrimary" fontWeight="bold">
        20% discount on all plans! Use code{` `}
        <Link href={routes.pricing.href} isPlain underline>
          FEB2024
        </Link>
        {` `}
        at checkout.
      </P>
    </div>
  );
};
