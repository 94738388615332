import { IS_DEV } from "utils/environment";

import { MonthlyToolUseCounts } from "../types/app/User";
import { PlanConfig, PlanTier } from "../types/Plan";

export const MAX_LISTING_OPTIMIZER_LISTINGS = 325;

export const freeMonthlyUses: MonthlyToolUseCounts = {
  keywordFinderCountMonth: 15,
  shopAnalyzerCountMonth: 10,
  listingAnalyzerCountMonth: 10,
  listingOptimizerCountMonth: 10,
  aiListingDescriptionCountMonth: 10,
  aiAdCaptionCountMonth: 0,
  titleGeneratorCountMonth: 0,
};

export const sparkMonthlyUses: MonthlyToolUseCounts = {
  keywordFinderCountMonth: 100,
  shopAnalyzerCountMonth: 75,
  listingAnalyzerCountMonth: 75,
  listingOptimizerCountMonth: 100,
  aiListingDescriptionCountMonth: 75,
  aiAdCaptionCountMonth: 75,
  titleGeneratorCountMonth: 75,
};

export const blazeMonthlyUses: MonthlyToolUseCounts = {
  keywordFinderCountMonth: Infinity,
  shopAnalyzerCountMonth: Infinity,
  listingAnalyzerCountMonth: Infinity,
  listingOptimizerCountMonth: MAX_LISTING_OPTIMIZER_LISTINGS,
  aiListingDescriptionCountMonth: Infinity,
  aiAdCaptionCountMonth: Infinity,
  titleGeneratorCountMonth: Infinity,
};

const FREE: PlanConfig = {
  monthlyLimits: freeMonthlyUses,
  priceMonth: 0,
  priceYear: 0,
  tier: PlanTier.FREE,
  description: "Ideal for new Etsy sellers just getting started.",
  label: "Free",
};

const SPARK: PlanConfig = {
  monthlyLimits: sparkMonthlyUses,
  priceMonth: 7.49,
  priceYear: 4.99 * 12,
  tier: PlanTier.SPARK,
  description:
    "For growing Etsy sellers wanting to take their shop to the next level.",
  label: "Spark",
  stripeConfig: IS_DEV
    ? {
        priceIdMonthly: "price_1NpdBaLx1I7peFtcJGF0rBZw",
        priceIdYearly: "price_1NpcXtLx1I7peFtcaYjW617M",
      }
    : {
        priceIdMonthly: "price_1NpcSHLx1I7peFtcXQlHdeL6",
        priceIdYearly: "price_1NpcSHLx1I7peFtciDX3EFVN",
      },
};

const BLAZE: PlanConfig = {
  monthlyLimits: blazeMonthlyUses,
  priceMonth: 14.99,
  priceYear: 9.99 * 12,
  tier: PlanTier.BLAZE,
  description: "For established Etsy sellers scaling their business.",
  label: "Blaze",
  stripeConfig: IS_DEV
    ? {
        priceIdMonthly: "price_1NpcYZLx1I7peFtcjST2kCWs",
        priceIdYearly: "price_1NpcYZLx1I7peFtcQvkefjy7",
      }
    : {
        priceIdMonthly: "price_1Npbg2Lx1I7peFtcrW7FPWtK",
        priceIdYearly: "price_1NpcQqLx1I7peFtcNwYcx3Sn",
      },
};

export const PLAN_CONFIGS: Record<PlanTier, PlanConfig> = {
  [PlanTier.FREE]: FREE,
  [PlanTier.SPARK]: SPARK,
  [PlanTier.BLAZE]: BLAZE,
};

export const BASE_DOMAIN = IS_DEV
  ? "http://localhost:3000"
  : "https://productflint.com";
