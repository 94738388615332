import { createContext } from "react";
import { ListingsSEOSummary } from "@productflint/api/src/types/app/ListingSEO";

interface ListingsContextType {
  isLoading: boolean;
  seoSummary?: ListingsSEOSummary;
}

export const ListingsContext = createContext<ListingsContextType>({
  isLoading: false,
});
