import React from "react";
import Head from "next/head";

export const Fonts: React.FC = () => (
  <Head>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link
      rel="preconnect"
      href="https://fonts.gstatic.com"
      crossOrigin="crossorigin"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;800&display=swap"
      rel="stylesheet"
    />
  </Head>
);
