// eslint-disable-next-line
import "utils/polyfills";

import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import { configure } from "axios-hooks";
import clsx from "clsx";
import mixpanel from "mixpanel-browser";
import type { AppProps } from "next/app";
import { DefaultSeo, NextSeo } from "next-seo";

import { productFlintAPI } from "api";
import { AppDataProvider } from "components/App/AppDataProvider";
import { AuthenticatedPage } from "components/Auth/AuthenticatedPage";
import { ConfirmProvider } from "components/Confirm";
import { GoogleAnalytics } from "components/Head/Analytics/GoogleAnalytics";
import { MixPanel } from "components/Head/Analytics/MixPanel";
import { Favicon } from "components/Head/Favicon";
import { Fonts } from "components/Head/Fonts";
import { Scripts } from "components/Head/Scripts";
import { Container } from "components/Layout/Container";
import { LimitUpgradeModal } from "components/LimitReachedModal";
import Sidebar from "components/Navigation/Sidebar";
import { config } from "constants/app";
import { routes } from "constants/routes";
import { AuthStateProvider } from "hooks/useFirebaseAuth";
import { IS_PROD } from "utils/environment";
import { getBrowserFingerprint } from "utils/finger";
import { defaultToastOptions, ToastContainer } from "utils/toast";

import "../api/etsyApi";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "styles/globals.scss";
import { TrackDesk } from "components/Head/TrackDesk";

if (typeof window !== "undefined" && IS_PROD) {
  const browserId = getBrowserFingerprint().toString();

  Sentry.setUser({ id: browserId });
}

if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    debug: !IS_PROD,
    ignore_dnt: true,
  });
}

configure({ axios: productFlintAPI });

const theme = extendTheme({
  components: {
    Form: {
      baseStyle: {
        container: {
          label: {
            marginBottom: "4px",
          },
        },
      },
    },
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  breakpoints: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
  },
});

const MyApp = ({ Component, pageProps }: AppProps) => (
  <div
    className={clsx({
      ["dev"]: !IS_PROD,
    })}
  >
    <AuthStateProvider>
      <ChakraProvider
        theme={theme}
        toastOptions={{ defaultOptions: defaultToastOptions }}
      >
        <Scripts />
        <GoogleAnalytics />
        <TrackDesk />
        <MixPanel />
        <Fonts />
        <DefaultSeo
          defaultTitle={`${config.appName} | Tools for Etsy sellers`}
          description="The simple all-in-one platform that helps you grow your shop and most importantly your sales!"
          openGraph={{
            type: "website",
            url: config.publicUrl,
            site_name: config.appName,
            description: `${config.appName}, the simple all-in-one platform that helps you grow your shop and most importantly your sales!`,
            images: [
              {
                url: `${config.publicUrl}/og-image.png`,
                height: 630,
                width: 1200,
              },
            ],
          }}
        />
        <Favicon />
        {
          // @ts-ignore
          Component.requireAuth ? (
            <AuthenticatedPage>
              <NextSeo title={`${routes.dashboard.name} | ${config.appName}`} />
              <ConfirmProvider>
                <AppDataProvider>
                  <LimitUpgradeModal>
                    <Sidebar>
                      <Container size="large">
                        <Component {...pageProps} />
                      </Container>
                    </Sidebar>
                  </LimitUpgradeModal>
                </AppDataProvider>
              </ConfirmProvider>
            </AuthenticatedPage>
          ) : (
            <Component {...pageProps} />
          )
        }
      </ChakraProvider>
    </AuthStateProvider>
    <ToastContainer />
  </div>
);

export default MyApp;
