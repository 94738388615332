import React from "react";

import {
  Typography,
  TypographyBaseProps,
} from "components/Typography/TypographyElements/Typography";

interface PProps extends TypographyBaseProps {}

export const P: React.FC<PProps> = ({
  children,
  fontColor = "primary",
  fontSize = "md",
  fontWeight = "normal",
  ...rest
}) => (
  <Typography
    element="p"
    fontColor={fontColor}
    fontSize={fontSize}
    fontWeight={fontWeight}
    {...rest}
  >
    {children}
  </Typography>
);
