import Script from "next/script";

import { isFeatureEnabled } from "constants/app";
import { IS_PROD } from "utils/environment";

export const Scripts = () => (
  <>
    {IS_PROD && (
      <Script
        src="https://cdn-cookieyes.com/client_data/6e863ac380ac12cd0f82e97a/script.js"
        type="text/javascript"
        id="cookieyes"
      />
    )}
    {IS_PROD && isFeatureEnabled("enableAdsense") && (
      <Script
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4128403181413578"
        async
        crossOrigin="anonymous"
      />
    )}
  </>
);
