const isUrl = (urlString: string) =>
  new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ).test(urlString);

export const validateNoSymbols = (
  value: string,
  message: string = "Please only enter letters, no symbols."
) => {
  if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
    return message;
  }
};

export const validateNoSpaces = (
  value: string,
  message: string = "Please enter no spaces."
) => {
  if (!/^\S*$/.test(value)) {
    return message;
  }
};

export const validateNotUrl = (
  value: string,
  message: string = "Please don't enter a URL."
) => {
  if (isUrl(value)) {
    return message;
  }
};
