import { MonthlyToolUseCounts } from "./app/User";

export enum PlanTier {
  FREE = "FREE",
  SPARK = "SPARK",
  BLAZE = "BLAZE",
}

export enum PaymentFrequency {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export interface UserPlanStripe {
  planTier: PlanTier;
  paymentFrequency: PaymentFrequency;
  billingAnchor: number;
  isLifetimeFree?: boolean;
  stripeCustomerId: string;
  stripeSubscriptionId: string;
  stripePriceId: string;
  stripeStatus: string;
  cancelsAt?: number;
}

export interface StripeConfig {
  priceIdMonthly: string;
  priceIdYearly: string;
}

export interface PlanConfig {
  monthlyLimits: MonthlyToolUseCounts;
  priceMonth: number;
  priceYear: number;
  tier: PlanTier;
  description: string;
  label: string;
  stripeConfig?: StripeConfig;
}
