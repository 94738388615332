import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  Modal,
} from "@chakra-ui/react";

export type Confirm = (
  title: string | ReactNode,
  message: string | ReactNode,
  opts?: ModalOpenerProps
) => Promise<boolean | null>;

export type ModalOpenerProps = {
  okText?: string;
  cancelText?: string;
  icon?: ReactNode;
  modalProps?: Partial<React.ComponentProps<typeof Modal>>;
  okButtonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
};

const defaultContext: Confirm = () => {
  throw new Error("<ModalProvider> is missing");
};

const Context = createContext<Confirm>(defaultContext);

interface AnyEvent {
  preventDefault(): void;
}

export const ConfirmProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<ReactNode | null>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const createOpener = useCallback(
    () =>
      (
        title: string,
        message: string,
        opts: ModalOpenerProps & { defaultValue?: string } = {}
      ) =>
        new Promise<boolean | string | undefined>((resolve) => {
          const handleClose = (e?: AnyEvent) => {
            e?.preventDefault();
            setModal(null);
            resolve(undefined);
          };

          const handleNo = (e?: AnyEvent) => {
            e?.preventDefault();
            setModal(null);
            resolve(false);
          };

          const handleOk = (e?: AnyEvent) => {
            e?.preventDefault();
            setModal(null);
            resolve(true);
          };

          console.log(opts);

          setModal(
            <AlertDialog
              isOpen={true}
              onClose={handleClose}
              leastDestructiveRef={cancelRef}
              isCentered={true}
              {...opts.modalProps}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    {title}
                  </AlertDialogHeader>
                  <AlertDialogBody>{message}</AlertDialogBody>
                  <AlertDialogFooter>
                    <Button
                      ref={cancelRef}
                      onClick={handleNo}
                      {...opts.cancelButtonProps}
                    >
                      {opts.cancelText || "Cancel"}
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={handleOk}
                      ml={3}
                      {...opts.okButtonProps}
                    >
                      {opts.okText || "Yes"}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          );
        }),
    [children]
  );

  return (
    <Context.Provider value={createOpener() as Confirm}>
      {children}
      {modal}
    </Context.Provider>
  );
};

export const useConfirm = () => useContext(Context);
