import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";

import { linkShop } from "api/userApi";
import { P } from "components/Typography";
import { APIState } from "types/ApiState";
import { MixPanelEvent } from "types/MixPanel";
import { handleApiError } from "utils/error";
import { toast } from "utils/toast";
import { validateNotUrl } from "utils/validators";

interface CreateAccountModalProps {
  onClose: () => void;
  isOpen: boolean;
  onChange?: () => void;
}

type FormValues = {
  shopName: string;
};

export const ChangeShopNameModal: React.FC<CreateAccountModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [loadingText, setLoadingText] = useState("Saving shop");
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);

  useEffect(() => {
    setApiState(APIState.IDLE);

    setTimeout(() => {
      reset();
    }, 500);
  }, [isOpen]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(async ({ shopName }) => {
    try {
      setApiState(APIState.LOADING);
      setTimeout(() => {
        setLoadingText("Analyzing listings");
      }, 4000);
      const { id } = await linkShop(shopName);
      mixpanel.track(MixPanelEvent.LINKED_SHOP, { shopName, shopId: id });
      toast({
        title: `Successfully linked ${shopName}.`,
        status: "success",
      });
      onClose();
    } catch (err: unknown) {
      const error = handleApiError(err, {
        type: "newsletter_signup",
        allowStatusCodes: [404],
        meta: {
          shopName,
        },
      });

      if (error) {
        toast({
          title: `No shop with name "${shopName}" found.`,
          status: "error",
        });
      }
    } finally {
      setApiState(APIState.IDLE);
    }
  });

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalHeader>Connect Etsy Shop</ModalHeader>
          <ModalCloseButton isDisabled={apiState === APIState.LOADING} />
          <ModalBody className="text-center">
            <FormControl isInvalid={!!errors.shopName}>
              <FormLabel>Etsy Shop name</FormLabel>
              <Input
                id="shopName"
                type="shopName"
                placeholder="e.g. CaitlynMinimalist"
                {...register("shopName", {
                  required: "Shop name is required.",
                  validate: (value) =>
                    validateNotUrl(
                      value,
                      "Please only enter the shop name, not a URL."
                    ),
                })}
              />
              <FormErrorMessage>
                {errors.shopName && errors.shopName.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <div>
              <div className="flex">
                <Button
                  className="ml-auto"
                  colorScheme="blue"
                  isLoading={apiState === APIState.LOADING}
                  loadingText={loadingText}
                  type="submit"
                >
                  Connect Shop
                </Button>
              </div>
              <div>
                <P className="mt-1" fontColor="secondary" fontSize="sm">
                  It can take up to 1 minute to sync all shop data
                </P>
              </div>
            </div>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
