import axios from "axios";
import axiosRetry from "axios-retry";

import { retryDelay } from "utils/api";

export const productFlintAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL || "http://localhost:4000/api/",
});

axiosRetry(productFlintAPI, {
  retries: 2,
  retryDelay,
  retryCondition: axiosRetry.isRetryableError,
});
