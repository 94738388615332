import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_whljgVwG7TGrlxHNZXsD76kG9cIgplI",
  authDomain: "etsy-tools-5eb6a.firebaseapp.com",
  projectId: "etsy-tools-5eb6a",
  storageBucket: "etsy-tools-5eb6a.appspot.com",
  messagingSenderId: "1037695452067",
  appId: "1:1037695452067:web:33989c1d4ef6035a3f1016",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseDB = getFirestore(firebaseApp);

export const firebaseAuth = getAuth(firebaseApp);

export const googleAuthProvider = new GoogleAuthProvider();
