import React, { ReactNode, useContext, useState } from "react";
import { FaBars, FaCommentAlt, FaEtsy, FaSignOutAlt } from "react-icons/fa";
import {
  Box,
  Button,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  IconButton,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { User } from "@productflint/api/src/types/app/User";
import clsx from "clsx";
import mixpanel from "mixpanel-browser";

import { ChangeShopNameModal } from "components/App/ChangeShopNameModal";
import { UserContext } from "components/App/UserContext";
import { Banner } from "components/Banner";
import { Link } from "components/Link";
import { Logo } from "components/Logo/Logo";
import {
  LinkConfig,
  SidebarItems,
} from "components/Navigation/Sidebar/SidebarItems";
import { P } from "components/Typography";
import { isFeatureEnabled } from "constants/app";
import { feedbackFormLink, routes } from "constants/routes";
import { useAuth } from "hooks/useFirebaseAuth";
import { MixPanelEvent } from "types/MixPanel";
import { shouldShowDiscountBanner } from "utils/discount";

import s from "./Sidebar.module.scss";

export default function Sidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = useContext(UserContext);
  const showDiscountBanner = shouldShowDiscountBanner(user);

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.50", "gray.900")}>
      {showDiscountBanner && <Banner className="fixed w-100 z-10" />}
      <div className={clsx({ "pt-10": showDiscountBanner })}>
        <SidebarContent
          className="hidden md:block"
          onClose={() => onClose}
          user={user}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} user={user} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        <div className="md:ml-72">{children}</div>
      </div>
    </Box>
  );
}

interface SidebarContentProps {
  onClose: () => void;
  className?: string;
  user: User;
}

const SidebarContent: React.FC<SidebarContentProps> = ({
  onClose,
  className,
  user,
}) => {
  const [isChangeShopModalOpen, setIsChangeShopModalOpen] = useState(false);
  const { signOut } = useAuth();
  const showDiscountBanner = shouldShowDiscountBanner(user);

  const links: LinkConfig[] = [
    routes.dashboard,
    {
      name: "SEO Tools",
      children: [routes.keywordFinderApp, routes.listingOptimizer],
      type: "group",
    },
    {
      name: "Insight Tools",
      children: [routes.shopAnalyzer, routes.listingExplorer],
      type: "group",
    },
    {
      name: "Tag Tools",
      children: [routes.tagGeneratorApp, routes.tagExtractor],
    },
    {
      name: "Other",
      children: [
        {
          name: "Feedback",
          icon: <FaCommentAlt />,
          href: feedbackFormLink,
          isNew: true,
          onClick: () => {
            mixpanel.track(MixPanelEvent.LINK_CLICKED, {
              href: feedbackFormLink,
            });
          },
        },
        routes.settings,
        {
          ...routes.contact,
          name: "Support",
        },
        {
          name: "Logout",
          icon: <FaSignOutAlt />,
          onClick: signOut,
        },
      ],
    },
  ];

  return (
    <>
      <div
        className={clsx(
          "bg-white border-r border-gray-200 h-screen w-full md:w-72 fixed",
          className
        )}
      >
        <div
          className={clsx("flex flex-col h-screen", s.sidebar, {
            [s.banner]: showDiscountBanner,
          })}
        >
          <div>
            <div className="w-full justify-center py-10 hidden md:flex">
              <Link isPlain href={routes.home.href}>
                <Logo />
              </Link>
            </div>
          </div>
          <div className="overflow-auto flex-1">
            <CloseButton
              className="m-2 ml-auto "
              display={{ base: "flex", md: "none" }}
              onClick={onClose}
              size="lg"
            />
            <div className="px-4">
              <SidebarItems links={links} onClick={onClose} />
            </div>
          </div>
          <div>
            {(isFeatureEnabled("enablePricing") || user.enablePricing) && (
              <>
                {!user.plan && (
                  <Link
                    isPlain
                    href={routes.upgrade.href}
                    className="flex justify-center mb-2"
                  >
                    <Button
                      className="w-full mx-4 mb-2"
                      colorScheme="green"
                      onClick={() => {
                        mixpanel.track(MixPanelEvent.CLICKED_SIDEBAR_UPGRADE);
                        onClose();
                      }}
                    >
                      Upgrade Plan
                    </Button>
                  </Link>
                )}
              </>
            )}
            <div className="w-full px-2 py-4 border-t border-gray-200">
              {user.shop ? (
                <Link href={routes.settings.href} isPlain>
                  <div className="flex justify-center items-center gap-2 cursor-pointer">
                    <img
                      className="h-12 w-12 rounded-full"
                      src={user.shop.logoSrc}
                      alt={`${user.shop.name} avatar`}
                    />
                    <P fontWeight="medium">{user.shop.name}</P>
                  </div>
                </Link>
              ) : (
                <div className="flex justify-center">
                  <Button
                    onClick={() => setIsChangeShopModalOpen(true)}
                    leftIcon={<FaEtsy />}
                    colorScheme="orange"
                  >
                    Connect Etsy Shop
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ChangeShopNameModal
        isOpen={isChangeShopModalOpen}
        onClose={() => setIsChangeShopModalOpen(false)}
      />
    </>
  );
};

const MobileNav = ({ onOpen }: { onOpen: () => void }) => (
  <Flex
    display={{ base: "flex", md: "none" }}
    ml={{ base: 0, md: 60 }}
    px={{ base: 4, md: 4 }}
    height="20"
    alignItems="center"
    bg={useColorModeValue("white", "gray.900")}
    borderBottomWidth="1px"
    borderBottomColor={useColorModeValue("gray.200", "gray.700")}
    justifyContent={{ base: "space-between", md: "flex-end" }}
  >
    <IconButton
      display={{ base: "flex", md: "none" }}
      onClick={onOpen}
      variant="outline"
      aria-label="open menu"
      icon={<FaBars />}
    />
    <div className="block lg:hidden">
      <Link href={routes.dashboard.href} isPlain>
        <Logo />
      </Link>
    </div>
    <div className="h-10 w-10" />
  </Flex>
);
