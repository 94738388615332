import * as Sentry from "@sentry/nextjs";
import axios, { AxiosError } from "axios";

import { toast } from "utils/toast";

interface HandleAPIErrorConfig {
  allowStatusCodes?: number[];
  type?: string;
  meta?: { [key: string]: any };
}

export const handleApiError = (
  err: unknown,
  { type, meta, allowStatusCodes }: HandleAPIErrorConfig
) => {
  if (axios.isCancel(err)) {
    return;
  }

  if (axios.isAxiosError(err)) {
    const error = err as AxiosError;

    if (
      error.response?.status &&
      allowStatusCodes?.includes(error.response?.status)
    ) {
      return error;
    }

    // real api err
    Sentry.captureException(err, {
      tags: {
        status: error.response?.status,
        statusText: error.response?.statusText,
        url: error.request?.url,
      },
    });

    toast({
      title: "Sorry, something went wrong. 😔",
      status: "error",
    });

    return undefined;
  }

  if (type) {
    Sentry.captureException(err);

    toast({
      title: "Sorry, something went wrong. 😔",
      status: "error",
    });

    return undefined;
  }
};
