export const typographyColorClassNames = {
  primary: "text-stone-800",
  secondary: "text-gray-500",
  accent: "text-blue-500",
  inversePrimary: "text-gray-100",
  inverseSecondary: "text-gray-200",
  inverseAccent: "text-blue-300",
};

export const typographyFontSizeClassNames = {
  xs: "text-xs",
  sm: "text-sm",
  md: "text-base",
  lg: "text-lg",
  xl: "md:text-xl text-lg",
  "2xl": "md:text-2xl text-xl",
  "3xl": "md:text-3xl text-2xl",
  "4xl": "md:text-4xl text-3xl",
  "5xl": "md:text-5xl md:leading-[3.5rem] text-4xl",
  "6xl": "md:text-6xl leading-[3.5rem] text-5xl",
};

export const typographyTrackingClassNames = {
  tight: "tracking-tighter",
  normal: "tracking-normal",
  wide: "tracking-wider",
  widest: "tracking-widest",
};

export const typographyFontWeightClassNames = {
  light: "font-light",
  normal: "font-normal",
  medium: "font-medium",
  semiBold: "font-semibold",
  bold: "font-bold",
  black: "font-black",
};

export const typographyCasingClassNames = {
  uppercase: "uppercase",
  lowercase: "lowercase",
};

export type TypographyColor = keyof typeof typographyColorClassNames;

export type TypographySize = keyof typeof typographyFontSizeClassNames;

export type TypographyTracking = keyof typeof typographyTrackingClassNames;

export type TypographyFontWeight = keyof typeof typographyFontWeightClassNames;

export type TypographyCasing = keyof typeof typographyCasingClassNames;
