export enum MixPanelEvent {
  EMAIL_VERIFICATION_RESENT = "email_verification_resent",
  KEYWORD_SEARCH = "keyword_search",
  KEYWORD_SEARCH_PUBLIC = "keyword_search_public",
  LISTING_ANALYZED = "listing_analyzed",
  SHOP_ANALYZED = "shop_analyzed",
  CONTACT_SUBMITTED = "contact_submitted",
  KEYWORD_SEARCH_ACCOUNT_WALL_SHOWN = "keyword_search_account_wall_shown",
  TAG_GENERATOR_ACCOUNT_WALL_SHOWN = "tag_generator_account_wall_shown",
  CSV_DOWNLOAD = "csv_download",
  NEWSLETTER_REGISTER = "newsletter_register",
  AI_COMPLETION = "ai_completion",
  TAG_EXTRACTED = "tag_extracted",
  TAGS_GENERATED = "tags_generated",
  SIGNED_UP = "signed_up",
  PAGE_VIEW = "page_view",
  TITLE_GENERATED = "title_generated",
  GELATO_LINK_CLICK = "gelato_link_click",
  LINK_CLICKED = "link_clicked",
  LINKED_SHOP = "linked_shop",
  SYNCED_SHOP = "synced_shop",
  SYNCED_LISTINGS = "synced_listings",
  SYNCED_LISTING = "synced_listing",
  REMOVED_SHOP = "removed_shop",
  CLICKED_SIDEBAR_UPGRADE = "clicked_sidebar_upgrade",
  CLICKED_LISTING_OPTIMIZER_ROW_UPGRADE = "clicked_listing_optimizer_row_upgrade",
  CLICKED_PAYWALL_SHOW_PLANS = "click_paywall_show_plans",
  UPDATED_PASSWORD = "updated_password",
  CLICKED_CHECKOUT = "clicked_checkout",
  CLICKED_CHECKOUT_STRIPE = "clicked_checkout_stripe",
  CLICKED_UPGRADE = "clicked_upgrade",
  CLICKED_VIEW_BILLING_PORTAL = "clicked_view_billing_portal",
  AFFILIATE_PROMO_CLICK = "clicked_affiliate_promo",
}
