import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { MonthlyToolUseCounts } from "@productflint/api/src/types/app/User";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";

import { UserContext } from "components/App/UserContext";
import { isFeatureEnabled } from "constants/app";
import { routes } from "constants/routes";
import { MixPanelEvent } from "types/MixPanel";
import { isToolDisabled } from "utils/user";

export type LimitReachedModal = (
  toolKey: keyof MonthlyToolUseCounts
) => Promise<boolean | null>;

const defaultContext: LimitReachedModal = () => {
  throw new Error("<ModalProvider> is missing");
};

const Context = createContext<LimitReachedModal>(defaultContext);

interface AnyEvent {
  preventDefault(): void;
}

export const LimitUpgradeModal = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<ReactNode | null>(null);
  const user = useContext(UserContext);
  const router = useRouter();

  const createOpener = useCallback(
    () => (toolKey: keyof MonthlyToolUseCounts) =>
      new Promise<boolean | string | undefined>((resolve) => {
        if (
          !isToolDisabled(user, toolKey) ||
          !(isFeatureEnabled("enablePricing") || user.enablePricing)
        ) {
          setModal(null);
          resolve(true);
          return null;
        }

        const handleClose = (e?: AnyEvent) => {
          e?.preventDefault();
          setModal(null);
          resolve(false);
        };

        const handleViewUsage = (e?: AnyEvent) => {
          e?.preventDefault();
          setModal(null);
          resolve(false);
          router.push(routes.settingsUsage.href);
        };

        const handleClickUpgrade = (e?: AnyEvent) => {
          e?.preventDefault();
          setModal(null);
          resolve(false);
          mixpanel.track(MixPanelEvent.CLICKED_PAYWALL_SHOW_PLANS, {
            toolKey,
          });
          router.push(routes.upgrade.href);
        };

        setModal(
          <Modal isOpen={true} onClose={handleClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Monthly limit reached</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Please upgrade your plan to continue using this tool.
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={handleViewUsage}>
                  View usage
                </Button>
                <Button onClick={handleClickUpgrade} colorScheme="green">
                  View Plans
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }),
    [children, user]
  );

  return (
    <Context.Provider value={createOpener() as LimitReachedModal}>
      {children}
      {modal}
    </Context.Provider>
  );
};

export const useToolLimitModal = () => useContext(Context);
